import styled from "styled-components";
import AppLayout from "../Layouts/AppLayout";

const Register = () => {
  return (
    <AppLayout>
      <Wrapper>
        <h1>register</h1>
      </Wrapper>
    </AppLayout>
  );
};

const Wrapper = styled.div``;

export default Register;
