import AppLayout from "../Layouts/AppLayout";

const Profile = () => {
  return (
    <AppLayout>
      <h1>Profile</h1>
    </AppLayout>
  );
};

export default Profile;
