export const styleVariables = {
  padding: "5px",
  padding1: "10px",
  padding2: "20px",
  padding3: "30px",
  textSize: "16px",
  smallerTextSize: "14px",
  smallestTextSize: "12px",
  headingSize1: "16px",
  headingSize2: "24px",
  headingSize3: "28px",
  primaryTextColor: "#424141",
  secondaryTextColor: "#687382",
  accentTextColor: "#09AEFF",
  accentColorBlue: "#09AEFF",
  accentColorGreen: "#2ECA72",
  accentColorOrange: "#FF855C",
  accentColorPink: "#EC63D2",
  accentColorRed: "#EC6363",
  bgColor1: "#F1F2F5",
  heading1Size: "32px",
  heading2Size: "24px",
  heading3Size: "20px",
  heading4Size: "18px",
};
